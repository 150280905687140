<template>
  <div class="user-agreement">
    <UserAgreement class="mt-s16 mb-s32" :style="{'max-height': '660px'}" />
    <div class="flex">
      <ButtonV2
        @onClick="tosDisagree"
        :label="$t('disagree')"
        size="medium"
        :inactive="isLoading"
        testId="btn-disagree"
        version="secondary"
        wide
      />
      <ButtonV2
        class="ml-s16"
        @onClick="agree"
        :label="$t('agree')"
        size="medium"
        testId="btn-agree"
        :inactive="isLoading"
        wide
      />
    </div>
  </div>
</template>

<script>
import { ButtonV2 } from '@/components/misc';
import UserAgreement from '@/views/UserAgreement';

export default {
  name: 'UserAgreementModal',
  components: {
    ButtonV2,
    UserAgreement,
  },

  beforeDestroy() {
    this.$root.$off('confirm-disagree-tos');
  },

  data: function () {
    return {
      isLoading: false,
    };
  },

  methods: {
    async agree() {
      if (!this.authData) {
        this.hideModal();
        return;
      }

      this.isLoading = true;
      try {
        const authResult = await this.ssoAuth(this.authData.ssoAuthToken, this.authData.ssoOrigin, '', true);
        await this.handleRegister(authResult, this.invite);
      } catch (err) {
        await this.showError(err);
      } finally {
        this.isLoading = false;
        this.hideModal();
      }
    }
  },

  mounted() {
    this.$root.$on('confirm-disagree-tos', () => {
      this.hideModal();
    });
  },

  props: {
    authData: {
      type: Object,
      default: null
    },

    invite: {
      type: String,
      default: null,
    }
  }
};
</script>

<style scoped>
  .user-agreement {
    max-width: 486px;
  }
</style>
